import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Box, Grid, Heading, Themed } from "theme-ui"

const ListImageFluid = ( props ) => {

  const color = props.color ? props.color : `#cfcfcf`
  const bg = props.bg ? props.bg : `black`
  const title = props.title
  const content = props.content
  const image = props.image ? props.image : null
  const textfirst = props.textfirst ? `'bgtext bgimage'` : `'bgimage bgtext'`

  return(
    <Box
      as={`section`}
      sx={{
        background: `${bg}`,
        "h2, h3, h4, li, p, a, span": {
          color: `${color}`,
        },
        h2: {
          color: `tertiary`,
          fontSize: [0, 1],
          mt: 0,
        },
        a: {
          borderBottom: 0,
          "&:hover": {
            borderBottom: 0,
          },
        },
        ".bg-text": {
          gridArea: `bgtext`,
          p: [4, 4, 4, 5, 6],
        },
        ".bg-image": {
          gridArea: `bgimage`,
        },
        ".content": {
          alignItems: `center`,
          gridTemplateAreas: [ `'bgimage' 'bgtext'`,`${textfirst}`],
        },
      }}
    >
      <Box as={`div`}>
        <Grid
          as={`div`}
          className={`content`}
          gap={`0`}
          columns={[1, 2]}
        >
          <Box as={`div`} className='bg-text'>
            {title &&
              <Heading as={`h2`}>{title}</Heading>
            }
            { content &&
              <Themed.ul>
                { content.map((item, index) => (
                  <Themed.li key={[index]}>
                    {item.link
                      ? <Link to={`/${item.link}`} rel="bookmark">{item.title}</Link>
                      : <span>{item.title}</span>
                    }
                  </Themed.li>
                )) }
              </Themed.ul>
            }
          </Box>
          {image &&
            <Box as={`div`} className='bg-image'>
              <GatsbyImage
                image={image}
                alt=""
              />
            </Box>
          }
        </Grid>
      </Box>
    </Box>
  )
}

export default ListImageFluid