import React from "react"
import { graphql } from "gatsby"
import Hero from "../components/hero"
import Layout from "../components/layout"
import Boxes from "../components/boxes"
import ListImageFluid from "../components/listimagefluid"
import Text from "../components/text"
import Logos from "../components/logos"
import Seo from "../components/seo"
import bg24 from "../images/bg-24.png"
import bg28 from "../images/bg-28.png"
import bg16 from "../images/bg-16.png"
import bg20 from "../images/bg-20.png"
import theme from "../gatsby-plugin-theme-ui/index"
// import Contact from "./contact"

const About = ({ data }) => {

  const hero = {
    'textintro': 'To extract the value of their data and address their business challenges.',
    'title': 'We help clients',
    'squares': '',
    'squaresmove': true,
  }

  const text1 = {
    'title': 'Who are we?',
    'content': {
      'text1': 'We are a European based, virtual business consultancy firm. We help our clients to increase efficiency and enhance profitability via data-driven, and ROI based, digital decisions.',
      'text2': 'Our senior, and multidisciplinary, teams deliver tailored strategic solutions that are designed to transform your businesses practices.',
      'link': '',
      'textlink': ''
    }
  }

  const box1 = {
    'title': 'Pragmatic framework',
    'content': [
      {
        'title': 'Data driven decisions',
        'content': 'Enable better decision making with end-to-end analytics for the intelligent enterprise. Make more informed decisions that will lead to commercial growth, evolution, and an increased bottom line.',
      },
      {
        'title': 'Run your business on-the-go',
        'content': 'Log in online anytime and anywhere on your Mac, PC, tablet or phone to see up-to-date financials. Plus, send invoices and expenses claims from your mobile phone. We make accounting software simple, smart and user friendly.',
      },
      {
        'title': 'Build up collaboration hubs',
        'content': 'Your communication and tools are all available in one place, helping remote teams to stay productive no matter where you are working from.',
      },
      {
        'title': 'Reach out the right prospects',
        'content': 'Flag underperforming market intelligence and eliminate the risk of undermining productivity and sales.'
      },
      {
        'title': 'Close deals faster and predict your revenue',
        'content': 'Simplify and shorten your sales process by reducing uncertainty and presenting a sequence of clear actions.'
      },
    ]
  }

  const list1 = {
    'title': 'Principles at work',
    'textfirst': false,
    'content': [
      {
        'title': 'Begin with your goal in mind',
        'link': '',
      },
      {
        'title': 'Start lean, but think big',
        'link': '',
      },
      {
        'title': 'Easily identify high value/low complex tasks',
        'link': '',
      },
      {
        'title': 'Iterate based on data insights',
        'link': '',
      },
      {
        'title': 'Transform your process and automate repeatable tasks',
        'link': '',
      }
    ]
  }

  return (
    <Layout>
      <Seo
        title={data.site.siteMetadata.title}
        description="We are a European based, virtual business consultancy firm. We help our clients to increase efficiency and enhance profitability via data-driven, and ROI based, digital decisions."
      />
      <Hero
        bg={`${theme.colors.primary} url(${bg24}) no-repeat left bottom`}
        color='primary'
        textintro={hero.textintro}
        title={hero.title}
        squares={hero.squares}
        squaresmove={hero.squaresmove}
      />
      <Text
        bg={`#1e1e1e url(${bg28}) no-repeat center top`}
        title={text1.title}
        content={text1.content}
      />
      <Boxes
        bg={`#1e1e1e url(${bg16}) no-repeat center center`}
        colorsquares='primary'
        title={box1.title}
        columns={box1.content.length}
        content={box1.content}
      />
      <ListImageFluid
        bg={`#1e1e1e url(${bg20}) no-repeat center bottom`}
        textfirst={list1.textfirst}
        title={list1.title}
        content={list1.content}
        image={data.line.childImageSharp.gatsbyImageData}
      />
      <Logos
        title='We Work With'
      />
    </Layout>
  )
}

export default About

export const query = graphql`
  query About {
    line: file(relativePath: {eq: "img-16.png"}) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
    	}
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`
